@font-face {
  font-family: 'Overpass';
  src: url('../../fonts/overpass/Overpass-Regular.eot');
  src: url('../../fonts/overpass/Overpass-Regular.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/overpass/Overpass-Regular.woff2') format('woff2'),
      url('../../fonts/overpass/Overpass-Regular.woff') format('woff'),
      url('../../fonts/overpass/Overpass-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Overpass';
  src: url('../../fonts/overpass/Overpass-SemiBold.eot');
  src: url('../../fonts/overpass/Overpass-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/overpass/Overpass-SemiBold.woff2') format('woff2'),
      url('../../fonts/overpass/Overpass-SemiBold.woff') format('woff'),
      url('../../fonts/overpass/Overpass-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Overpass';
  src: url('../../fonts/overpass/Overpass-Italic.eot');
  src: url('../../fonts/overpass/Overpass-Italic.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/overpass/Overpass-Italic.woff2') format('woff2'),
      url('../../fonts/overpass/Overpass-Italic.woff') format('woff'),
      url('../../fonts/overpass/Overpass-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Overpass';
  src: url('../../fonts/overpass/Overpass-Light.eot');
  src: url('../../fonts/overpass/Overpass-Light.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/overpass/Overpass-Light.woff2') format('woff2'),
      url('../../fonts/overpass/Overpass-Light.woff') format('woff'),
      url('../../fonts/overpass/Overpass-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Overpass';
  src: url('../../fonts/overpass/Overpass-ExtraBold.eot');
  src: url('../../fonts/overpass/Overpass-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/overpass/Overpass-ExtraBold.woff2') format('woff2'),
      url('../../fonts/overpass/Overpass-ExtraBold.woff') format('woff'),
      url('../../fonts/overpass/Overpass-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Overpass';
  src: url('../../fonts/overpass/Overpass-Bold.eot');
  src: url('../../fonts/overpass/Overpass-Bold.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/overpass/Overpass-Bold.woff2') format('woff2'),
      url('../../fonts/overpass/Overpass-Bold.woff') format('woff'),
      url('../../fonts/overpass/Overpass-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Overpass';
  src: url('../../fonts/overpass/Overpass-Black.eot');
  src: url('../../fonts/overpass/Overpass-Black.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/overpass/Overpass-Black.woff2') format('woff2'),
      url('../../fonts/overpass/Overpass-Black.woff') format('woff'),
      url('../../fonts/overpass/Overpass-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

