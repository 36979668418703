
// Booststrap functions and variables
@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';

// Template variables
@import '../light/variables';
@import './variables';

// Bootstrap main SCSS
@import '../../../node_modules/bootstrap/scss/bootstrap.scss';

// Template mixins
@import '../common/mixins/animation';
@import '../common/mixins/badges';
@import '../common/mixins/blockquote';
@import '../common/mixins/breadcrumbs';
@import '../common/mixins/buttons';
@import '../common/mixins/cards';
@import '../common/mixins/misc';
@import '../common/mixins/no_ui_slider';
@import '../common/mixins/pagination';
@import '../common/mixins/popovers';
@import '../common/mixins/tooltips';
@import '../common/mixins/width';

// Core stules
@import '../common/background';
@import '../common/reset';
@import '../common/functions';
@import '../common/fonts';
// @import url('https://fonts.googleapis.com/css?family=Overpass:300,400,600,700,800');
@import '../common/footer';
@import '../common/misc';
@import '../common/utilities';
@import '../common/demo';
@import '../common/typography';

@import './vertical-wrapper';
@import './navbar';
@import './sidebar';
@import './layouts';

// components
@import "../common/components/accordions";
@import "../common/components/badges";
@import "../common/components/bootstrap-alert";
@import "../common/components/breadcrumbs";
@import "../common/components/buttons";
@import "../common/components/cards";
@import "../common/components/checkbox-radio";
@import "../common/components/dashboard";
@import "../common/components/dropdown";
@import "../common/components/forms";
@import "../common/components/icons";
@import "../common/components/input-group";
@import "../common/components/list-group";
@import "../common/components/modal";
@import "../common/components/pagination";
@import "../common/components/popover";
@import "../common/components/profile";
@import "../common/components/tables";
@import "../common/components/timeline";
@import "../common/components/chat";
@import "../common/components/faq";
@import "../common/components/auth";

// Email
@import '../common/components/email/inbox';
@import '../common/components/email/email-read';
@import '../common/components/email/email-compose';


// Plugin overrides
@import "../light/components/plugin-overrides/ace";
@import "../light/components/plugin-overrides/apex-charts";
@import "../light/components/plugin-overrides/bootstrap-datepicker";
@import "../light/components/plugin-overrides/data-tables";
@import "../light/components/plugin-overrides/dropify";
@import "../light/components/plugin-overrides/dropzone";
@import "../light/components/plugin-overrides/full-calendar";
@import "../light/components/plugin-overrides/jquery-flot";
@import "../light/components/plugin-overrides/peity";
@import "../light/components/plugin-overrides/perfect-scrollbar";
@import "../light/components/plugin-overrides/sweet-alert";
@import "../light/components/plugin-overrides/select2";
@import "../light/components/plugin-overrides/simplemde";
@import "../light/components/plugin-overrides/tags-input";
@import "../light/components/plugin-overrides/tempusdominus-bootstrap-4";
@import "../light/components/plugin-overrides/tinymce";
@import "../light/components/plugin-overrides/typeahead";
@import "../light/components/plugin-overrides/wizard";


