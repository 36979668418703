.timeline {
  border-left: 3px solid theme-color(primary);
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;    
  background: rgba(theme-color(primary), .09);
  margin: 0 auto;  
  letter-spacing: 0.2px;   
  position: relative;
  line-height: 1.4em;
  font-size: 1.03em;   
  padding: 50px;
  list-style: none;
  text-align: left;  
  max-width: 40%; 
  @media(max-width: 767px) {
    max-width: 98%;
    padding: 25px;
  }
  
  h1 {
   font-weight: 300;
   font-size: 1.4em;    
  }
  
  h2, h3 {
   font-weight: 600;
   font-size: 1rem;
   margin-bottom: 10px;
  }
  
  .event {
    border-bottom: 1px dashed $border-color;
    padding-bottom: (50px * 0.5);
    margin-bottom: 25px;  
    position: relative;
    
    @media(max-width: 767px) {
      padding-top: 30px;
    }

    &:last-of-type { 
      padding-bottom: 0;
      margin-bottom: 0; 
      border: none;      
    }

    &:before, &:after {
      position: absolute;
      display: block;
      top: 0;
    }

    &:before {
      left: (((120px * 0.6) + 50px + 4px + 4px + (4px * 2)) * 1.5) * -1;    
      content: attr(data-date);
      text-align: right;
      font-weight: 100;    
      font-size: 0.9em;
      min-width: 120px;
      @media(max-width: 767px) {
        left: 0px;
        text-align: left;
      }
    }

    &:after {
      -webkit-box-shadow: 0 0 0 3px theme-color(primary);
              box-shadow: 0 0 0 3px theme-color(primary);    
      left: (50px + 3px + (8px * 0.35)) * -1;        
      background: $card-bg;    
      border-radius: 50%;  
      height: 9px;
      width: 9px;
      content: "";
      top: 5px;
      @media(max-width: 767px) {
        left: (25px + 4px + (8px * 0.35)) * -1;        
      }
    }
  }
}

.rtl {
  .timeline {
    border-left: 0;
    text-align: right;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    border-right: 3px solid theme-color(primary);
    .event {
      &::before {
        left: 0;
        right: -170px;
      }
      &::after {
        left: 0;
        right: -55.8px;
      }
    }
  }
}